import { Anchor } from '~/components/ui/link'

export default function Footer() {
    return (
        <div className='flex flex-col items-start gap-2 px-4 py-24'>
            <p className='font-semibold'>Infinity</p>
            <Anchor $size='primary' $style='secondary' href='/'>
                Home
            </Anchor>
            <Anchor $size='primary' $style='secondary' href='/release-notes'>
                Release notes
            </Anchor>
            {/* 
	<Anchor $size='primary' $style='secondary' href='/blog'>
		Blog
	</Anchor>
            <Anchor $size='primary' $style='secondary' href='/privacy'>
                Privacy
            </Anchor>
		*/}
            <Anchor $size='primary' $style='secondary' href='/terms'>
                Terms of use
            </Anchor>
            {/* 
				<Anchor $size='primary' $style='secondary' href='/cookies'>
					Cookie preferences
				</Anchor>
			*/}
        </div>
    )
}
